.clearBackground {
  background-color: transparent;
}

.fluid {
  width: 100%
}

.bp3-overlay {
  z-index: 501 !important;
}

.bp3-overlay-content {
  z-index: 501 !important;
}

.bp3-popover {
  z-index: 501 !important;
}

.bp3-overlay-backdrop {
  z-index: 501 !important;
}

.bp3-omnibar {
  z-index: 502 !important;
}

.bp3-transition-container {
  z-index: 501 !important;
}